<script setup lang="ts">
import TheIcon from '@/Components/TheIcon.vue'
import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import 'vue-lite-youtube-embed/style.css'
import { Link } from '@inertiajs/vue3'

type Props = {
    title?: string
    subTitle?: string
    btnUrl?: string
}

withDefaults(defineProps<Props>(), {
    title: 'We compare all hostel prices for you at',
    subTitle: 'Save money and time',
    btnUrl: 'howCompareHostelPrices'
})

function handleLearnMore (): void {
    // TODO: Add Google Analytics event
    // ga('send','event','Index','details', 'Learn more')
    console.log('ga send event')
}

</script>

<template>
    <section class="py-5 py-lg-6 bg-white">
        <div class="container">
            <div class="text-center mb-5">
                <p class="sb-title text-primary mb-2 text-left text-lg-center">
                    {{ subTitle }}
                </p>

                <h3 class="title-2 cl-dark mb-0 text-left text-lg-center">
                    {{ title }}
                </h3>
            </div>
        </div>

        <div class="container">
            <div class="row justify-content-around text-center mb-5">
                <div class="col-12 col-lg-6 d-flex justify-content-between">
                    <div class="hover-animate d-flex align-items-center">
                        <span class="d-none d-lg-inline">
                            <TheIcon icon-id="hostelworld-logo" width="120" height="27" />
                        </span>

                        <span class="d-inline d-lg-none">
                            <TheIcon icon-id="hostelworld-logo-2" width="96" height="22" />
                        </span>
                    </div>

                    <div class="hover-animate d-flex align-items-center">
                        <span class="d-none d-lg-inline">
                            <TheIcon icon-id="booking-logo" width="120" height="25" />
                        </span>

                        <span class="d-inline d-lg-none">
                            <TheIcon icon-id="booking-logo-2" width="97" height="17" />
                        </span>
                    </div>

                    <div class="hover-animate d-flex align-items-center">
                        <span class="d-none d-lg-inline">
                            <TheIcon icon-id="hostelclub-logo" width="130" height="40" />
                        </span>

                        <span class="d-inline d-lg-none">
                            <TheIcon icon-id="hostelclub-logo" width="100" height="30" />
                        </span>
                    </div>
                </div>
            </div>

            <div class="row justify-content-around text-center mb-5">
                <div class="col-12 col-8">
                    <div class="video-wrapper">
                        <LiteYouTubeEmbed
                            id="y0OrM1InNjs"
                            title="some title"
                            iframe-class="lazyload border-0"
                        />
                    </div>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <Link
                        :href="route(btnUrl)"
                        class="btn btn-primary btn-lg full-width rounded px-4 px-sm-5"
                        @click="handleLearnMore"
                    >
                        Learn more
                    </Link>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.video-wrapper {
    max-width: 560px;
    margin: 0 auto;
}
</style>